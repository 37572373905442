import React, { useState } from "react"
import { Col, Row } from "react-bootstrap"
import Buttons from "./../common/button"
import StoryCard from "./storyCard"

let AuthorStories = props => {
  const [itemsToBeShown, setItemsToBeShown] = useState(6)

  var totalPosts = props.posts.length
  return (
    // <div className="grid-container justify-content-center">
    // <Container className="Layout-container">
    <>
      <Row>
        {props.posts.map((post, i) => {
          const {
            title,
            excerpt,
            author,
            coverImage,
            tags,
            readTime,
            slug,
            isBlog,
          } = post
          return (
            <Col
              lg={props.colLg}
              sm={12}
              style={{
                display: i < itemsToBeShown ? "block" : "none",
              }}
            >
              <StoryCard
                title={title}
                excerpt={excerpt}
                author={author}
                coverImage={coverImage}
                tags={tags}
                readTime={readTime}
                slug={slug}
                contentEllipseLine="3"
                headingEllipseLine="2"
                headingEllipseLineMob="3"
                cardSize="small"
                contentMinHeight="368px"
                isBlog={isBlog}
                style={{ marginBottom: "48px" }}
                theme={props?.theme}
              />
            </Col>
          )
        })}
      </Row>
      {totalPosts <= 6 || itemsToBeShown >= totalPosts ? null : (
        <div
          className={`d-flex ${props.centerButton && "justify-content-center"}`}
        >
          <Buttons
            theme={props.theme === "dark" ? "secondary white" : "primary lg"}
            text="VIEW MORE"
            onClick={() => setItemsToBeShown(itemsToBeShown + 3)}
          />
        </div>
      )}
    </>
  )
}

export default AuthorStories
