import { graphql, Link, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import { Container, Row } from "react-bootstrap"
import AuthorStories from "../components/blog/authorStories"
import CTA from "../components/common/CTA"
import Frame from "../components/common/frame"
import SVGIcon from "../components/common/SVGIcon"
import NavigationBlog from "../components/navigationBlog"
import CheckLinkDomain from "../components/utility/current_domain"

let BlogAuthor = ({ pageContext }) => {
  const { author } = pageContext

  return (
    <>
      <StaticQuery
        query={graphql`
          query contentAuthorSingle {
            SuperOps {
              navigationContents(where: { section: "Library" }) {
                section
                isParentOnly
                parentTitle
                parentDescription
                parentTagBg
                childTitle
                childSlug
                childDescription
                childIcon {
                  url
                }
                parentTagSecondaryBg
                parentSlug
              }
              pages(where: { title: "Blog" }) {
                title
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
              }
              ctaBoxes(where: { where: "Subscribe CTA" }) {
                theme
                type
                heading {
                  html
                }
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.pages.map(page => {
              const { navigationBlock } = page
              return (
                <>
                  <Frame
                    title={author.seo[0].title}
                    description={author.seo[0].description}
                    keywords={author.seo[0].keywords}
                    ogTitle={author.seo[0].title}
                    ogDescription={author.seo[0].description}
                    ogImage={author.seo[0].image}
                    ogType="blog"
                  >
                    <NavigationBlog
                      links={navigationBlock}
                      background="#f5fdff"
                      libraryLinks={data.SuperOps.navigationContents}
                    />

                    <div className="blog-author blog">
                      <section
                        className="blog-author-hero"
                        style={{ background: "#f5fdff" }}
                      >
                        <Container>
                          <center>
                            <Row>
                              <div className="col-lg-8 col-md-8 left">
                                <h1>{author.name}</h1>
                                <div className="p16 title">
                                  <p>{author.title}</p>
                                </div>
                                <div className="social-links">
                                  {author.twitterUrl && (
                                    <Link
                                      to={author.twitterUrl}
                                      target={
                                        CheckLinkDomain(author.twitterUrl)
                                          ? "_self"
                                          : "_blank"
                                      }
                                      rel="noreferrer"
                                    >
                                      <SVGIcon
                                        name="social/icontwitter"
                                        className="objcvr"
                                      />
                                    </Link>
                                  )}
                                  {author.linkedInUrl && (
                                    <Link
                                      to={author.linkedInUrl}
                                      target={
                                        CheckLinkDomain(author.linkedInUrl)
                                          ? "_self"
                                          : "_blank"
                                      }
                                      rel="noreferrer"
                                    >
                                      <SVGIcon
                                        name="social/iconlinkedin"
                                        className="objcvr"
                                      />
                                    </Link>
                                  )}
                                  {author.portfolioUrl && (
                                    <Link
                                      to={author.portfolioUrl}
                                      target={
                                        CheckLinkDomain(author.portfolioUrl)
                                          ? "_self"
                                          : "_blank"
                                      }
                                      rel="noreferrer"
                                    >
                                      <SVGIcon
                                        name="social/linkicon"
                                        className="objcvr"
                                      />
                                    </Link>
                                  )}
                                </div>
                                <div className="p16 biography biography-link">
                                  {!!author.biographyWithLinks ? (
                                    parse(author.biographyWithLinks.html)
                                  ) : (
                                    <p>{author.biography}</p>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 right">
                                <div className="image-decoration">
                                  {/* <img
                                    src={AuthSpiral}
                                    className="image-decoration-spiral"
                                    alt="img"
                                  /> */}
                                  <SVGIcon
                                    name="blogauthor/auth-spiral"
                                    className="image-decoration-spiral"
                                  />
                                  {/* <img
                                    src={AuthSlash}
                                    className="image-decoration-slash"
                                    alt="img"
                                  /> */}
                                  <SVGIcon
                                    name="blogauthor/auth-slash"
                                    className="image-decoration-slash"
                                  />
                                  {/* <img
                                    src={AuthSpark}
                                    className="image-decoration-spark"
                                    alt="img"
                                  /> */}
                                  <SVGIcon
                                    name="blogauthor/auth-spark"
                                    className="image-decoration-spark"
                                  />
                                  {/* <img
              src={AuthArrow}
              className="image-decoration-arrow"
              alt="img"
            /> */}
                                </div>
                                {/* {author.name === "Arvind Parthiban" ? (<img className="author-image" src={APBlog} alt="Authorimage" />) : */}
                                <img
                                  className="author-image br-10"
                                  src={
                                    author.authorImage
                                      ? author.authorImage.url
                                      : author.picture.url
                                  }
                                  alt="Authorimage"
                                />
                              </div>
                            </Row>
                          </center>
                        </Container>
                      </section>
                      <section className="blog-author-stories">
                        <Container className="shadowbg"></Container>
                        <Container>
                          {/* <div className="blog-author-stories-container">
        <span className="p16 section-title">
          <p>AUTHOR STORIES</p>
        </span>
      </div> */}
                          <div className="blog-author-stories-display">
                            <span className="p16 section-title">
                              <p>AUTHOR STORIES</p>
                            </span>
                            <AuthorStories
                              posts={author.posts}
                              theme="light"
                              colLg={4}
                              centerButton
                            />
                          </div>
                        </Container>
                      </section>
                      <section className="blog-author-cta">
                        <CTA
                          data={data.SuperOps.ctaBoxes}
                          maxBoxWidth="1104px"
                        />
                      </section>
                    </div>
                  </Frame>
                </>
              )
            })}
          </>
        )}
      />
    </>
  )
}

export default BlogAuthor
